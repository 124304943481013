











































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component<CardArtLogo>({})
export default class CardArtLogo extends Vue {}
