// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".image-file-input .v-input__icon{display:none}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#203662",
	"secondaryColor": "#003c60",
	"accentColor": "#c41230"
};
module.exports = exports;
