import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4d36febf = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _61d7fdb6 = () => interopDefault(import('..\\pages\\patient-or-representative.vue' /* webpackChunkName: "pages_patient-or-representative" */))
const _70ed54c6 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _37d7f9dd = () => interopDefault(import('..\\pages\\upload-documents\\index.vue' /* webpackChunkName: "pages_upload-documents_index" */))
const _68982bc0 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _687bfcbe = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _68439eba = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _68276fb8 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _009a2561 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _4b1e6671 = () => interopDefault(import('..\\pages\\hcp\\eligibility.vue' /* webpackChunkName: "pages_hcp_eligibility" */))
const _1ec37470 = () => interopDefault(import('..\\pages\\hcp\\patient-information.vue' /* webpackChunkName: "pages_hcp_patient-information" */))
const _5d8233ed = () => interopDefault(import('..\\pages\\hcp\\rejection.vue' /* webpackChunkName: "pages_hcp_rejection" */))
const _4ff75de7 = () => interopDefault(import('..\\pages\\hcp\\success.vue' /* webpackChunkName: "pages_hcp_success" */))
const _3f374bc1 = () => interopDefault(import('..\\pages\\patient\\eligibility.vue' /* webpackChunkName: "pages_patient_eligibility" */))
const _4d3649d0 = () => interopDefault(import('..\\pages\\patient\\patient-information.vue' /* webpackChunkName: "pages_patient_patient-information" */))
const _9397a586 = () => interopDefault(import('..\\pages\\patient\\rejection.vue' /* webpackChunkName: "pages_patient_rejection" */))
const _6f112992 = () => interopDefault(import('..\\pages\\patient\\success.vue' /* webpackChunkName: "pages_patient_success" */))
const _0ab43ac0 = () => interopDefault(import('..\\pages\\pharmacist\\eligibility.vue' /* webpackChunkName: "pages_pharmacist_eligibility" */))
const _8f8349d2 = () => interopDefault(import('..\\pages\\pharmacist\\patient-information.vue' /* webpackChunkName: "pages_pharmacist_patient-information" */))
const _04626008 = () => interopDefault(import('..\\pages\\pharmacist\\rejection.vue' /* webpackChunkName: "pages_pharmacist_rejection" */))
const _e8ec9c94 = () => interopDefault(import('..\\pages\\pharmacist\\success.vue' /* webpackChunkName: "pages_pharmacist_success" */))
const _95b965f2 = () => interopDefault(import('..\\pages\\representative\\eligibility.vue' /* webpackChunkName: "pages_representative_eligibility" */))
const _7276365e = () => interopDefault(import('..\\pages\\representative\\patient-information.vue' /* webpackChunkName: "pages_representative_patient-information" */))
const _30c86ffa = () => interopDefault(import('..\\pages\\representative\\rejection.vue' /* webpackChunkName: "pages_representative_rejection" */))
const _eb1a0106 = () => interopDefault(import('..\\pages\\representative\\success.vue' /* webpackChunkName: "pages_representative_success" */))
const _0970e089 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _4d36febf,
    name: "error"
  }, {
    path: "/patient-or-representative",
    component: _61d7fdb6,
    name: "patient-or-representative"
  }, {
    path: "/unlock",
    component: _70ed54c6,
    name: "unlock"
  }, {
    path: "/upload-documents",
    component: _37d7f9dd,
    name: "upload-documents"
  }, {
    path: "/errors/400",
    component: _68982bc0,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _687bfcbe,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _68439eba,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _68276fb8,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _009a2561,
    name: "errors-500"
  }, {
    path: "/hcp/eligibility",
    component: _4b1e6671,
    name: "hcp-eligibility"
  }, {
    path: "/hcp/patient-information",
    component: _1ec37470,
    name: "hcp-patient-information"
  }, {
    path: "/hcp/rejection",
    component: _5d8233ed,
    name: "hcp-rejection"
  }, {
    path: "/hcp/success",
    component: _4ff75de7,
    name: "hcp-success"
  }, {
    path: "/patient/eligibility",
    component: _3f374bc1,
    name: "patient-eligibility"
  }, {
    path: "/patient/patient-information",
    component: _4d3649d0,
    name: "patient-patient-information"
  }, {
    path: "/patient/rejection",
    component: _9397a586,
    name: "patient-rejection"
  }, {
    path: "/patient/success",
    component: _6f112992,
    name: "patient-success"
  }, {
    path: "/pharmacist/eligibility",
    component: _0ab43ac0,
    name: "pharmacist-eligibility"
  }, {
    path: "/pharmacist/patient-information",
    component: _8f8349d2,
    name: "pharmacist-patient-information"
  }, {
    path: "/pharmacist/rejection",
    component: _04626008,
    name: "pharmacist-rejection"
  }, {
    path: "/pharmacist/success",
    component: _e8ec9c94,
    name: "pharmacist-success"
  }, {
    path: "/representative/eligibility",
    component: _95b965f2,
    name: "representative-eligibility"
  }, {
    path: "/representative/patient-information",
    component: _7276365e,
    name: "representative-patient-information"
  }, {
    path: "/representative/rejection",
    component: _30c86ffa,
    name: "representative-rejection"
  }, {
    path: "/representative/success",
    component: _eb1a0106,
    name: "representative-success"
  }, {
    path: "/",
    component: _0970e089,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
