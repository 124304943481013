import { Action, Mutation } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { Resolve } from 'vue-di';
import { EdgeCouponApi } from '@trialcard/apigateway.client';
import {
    CouponMemberMemberNumberStatusWithProgramMemberNumberStatusWithProgramRequest,
    CouponMemberMemberNumberStatusWithProgramMemberNumberStatusWithProgramResponse
} from '@trialcard/apigateway.models';
import { AxiosResponse } from 'axios';
import { Settings } from '~/services/settings';

@InjectModule({ stateFactory: true }, module)
export default class ActiveMemberNumberModule extends InjectVuexModule {
    @Resolve
    public edgeouponApi!: EdgeCouponApi;

    @Resolve
    public settings!: Settings;

    public memberNumber = '';

    @Action({})
    checkMemberNumberActivation(memberNumber: string): Promise<AxiosResponse<CouponMemberMemberNumberStatusWithProgramMemberNumberStatusWithProgramResponse>> {
        const requestModel: CouponMemberMemberNumberStatusWithProgramMemberNumberStatusWithProgramRequest = {
            memberId: memberNumber,
        };
        return this.edgeouponApi.memberMemberNumberStatusWithProgram(requestModel, this.settings.programId);
    }
}
