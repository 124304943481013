export class Settings {
[x: string]: any;
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    author: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    card: string;
    headerLogo: string;
    footerLogo: string;
    programId: string;

    surveyPatientId: string;
    surveyOtherId: string;

    eligibilityConsentPatientQuestion: string;
    eligibilityConsentPatientAnswerYes: string;
    eligibilityConsentPatientAnswerNo: string;

    eligibilityEnrollPatientQuestion: string;
    eligibilityEnrollPatientAnswerYes: string;
    eligibilityEnrollPatientAnswerNo: string;

    eligibilityCitizenPatientQuestion: string;
    eligibilityCitizenPatientAnswerYes: string;
    eligibilityCitizenPatientAnswerNo: string;

    eligibilityPrescriptionsPaidPatientQuestion: string;
    eligibilityPrescriptionsPaidPatientAnswerYes: string;
    eligibilityPrescriptionsPaidPatientAnswerNo: string;

    eligibilityAcknowledgePatientQuestion: string;
    eligibilityAcknowledgePatientAnswerYes: string;
    eligibilityAcknowledgePatientAnswerNo: string;

    eligibilityAgreeToLimitationPatientQuestion: string;
    eligibilityAgreeToLimitationPatientAnswerYes: string;
    eligibilityAgreeToLimitationPatientAnswerNo: string;

    eligibilityConsentQuestion: string;
    eligibilityConsentAnswerYes: string;
    eligibilityConsentAnswerNo: string;
    eligibilityEnrollQuestion: string;
    eligibilityEnrollAnswerYes: string;
    eligibilityEnrollAnswerNo: string;
    eligibilityAgreeToLimitationQuestion: string;
    eligibilityAgreeToLimitationAnswerYes: string;
    eligibilityAgreeToLimitationAnswerNo: string;
    eligibilityCitizenQuestion: string;
    eligibilityCitizenAnswerYes: string;
    eligibilityCitizenAnswerNo: string;
    eligibilityPrescription: string;
    eligibilityPrescriptionYes: string;
    eligibilityPrescriptionNo: string;
    eligibilityPrescriptionsPaidQuestion: string;
    eligibilityPrescriptionsPaidAnswerYes: string;
    eligibilityPrescriptionsPaidAnswerNo: string;

    eligibilityAcknowledgeQuestion: string;
    eligibilityAcknowledgeAnswerYes: string;
    eligibilityAcknowledgeAnswerNo: string;
    pathQuestion:string;
    representativePathAnswer: string;
    hcpPathAnswer: string;
    pharmacistPathAnswer: string;

    pathPatinet:string;
    pathPatinetAnswer:string;

    triangleUp: string;
    triangleDown: string;
    lockEnabled: string;
    lockPassword: string;
    patientConsentToInformationQuestionId: string;
    patientConsentToInformationAnswerId: string;
    patientConsentToMarketingQuestionId: string;
    patientConsentToMarketingAnswerId: string;
    otherConsentToInformationQuestionId: string;
    otherConsentToInformationAnswerId: string;
    otherConsentToMarketingQuestionId: string;
    otherConsentToMarketingAnswerId: string;
}
