import { Mutation } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';

export type IUserType = 'hcp' | 'patient' | 'pharmacist' | 'representative';

@InjectModule({ stateFactory: true }, module)
export default class PathModule extends InjectVuexModule {
    path = '';

    @Mutation
    setPath(path: 'hcp' | 'patient' | 'pharmacist' | 'representative') {
        this.path = path;
    }
}
